<template>
  <div class="group_wrap">
    <div
      class="img"
      @mouseenter="group_code_show = true"
      @mouseleave="group_code_show = false"
    >
      <img
        v-if="group_code_show"
        src="@/assets/mock_intro/mock_group_hover.png"
        alt=""
      />
      <img v-else src="@/assets/mock_intro/mock_group.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "mock_group",
  props: {},
  data() {
    return {
      group_code_show: false,
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.group_wrap {
  position: fixed;
  right: 5px;
  top: 30%;
}
.group_wrap .img {
  cursor: pointer;
}
</style>
