<template>
  <div class="page" ref="pay_page" id="pay_page">
    <div class="zhanwei">
      <!-- 占位 -->
    </div>
    <div class="pay_bg">
      <div class="page_connernt">
        <div class="top">
          <div class="left">
            <div class="title">选择支付方式</div>
            实付金额以第三方平台为准
          </div>
          <div class="right">
            需付款：
            <p>
              ￥
              <span>{{ order_info.price }}</span>
            </p>
          </div>
        </div>
        <ul class="tab_wrap">
          <li
            :class="index == current_index ? 'active' : ''"
            v-for="(item, index) in pay_List"
            :key="index"
            @click="chang_pay(index)"
          >
            <img :class="item.src ? '' : 'none'" :src="item.src" alt="" />
            {{ item.name }}
          </li>
          <!-- <li>
            <img src="@/assets/login/wx_icon.png" alt="" />
            微信支付
          </li>
          <li>学习币支付</li> -->
        </ul>
        <div class="bottom">
          <div class="left">
            <el-checkbox class="choose" v-model="agree_value"></el-checkbox>
            我已阅读并同意
            <p @click="go_web">《社科赛斯考研用户付费协议》</p>
          </div>

          <div :class="['btn']" @click="handlePay">立即支付</div>
        </div>
      </div>
    </div>

    <!-- 模考拼团 -->
    <div><mock_group></mock_group></div>

    <!-- 支付弹出窗 -->
    <el-dialog
      id="pay_dialog"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible_pay_status">
        <img src="@/assets/login/success_pay.png" alt="" />
        <p>支付成功</p>
        <p class="tip">正在为您跳转...</p>
      </div>
      <div v-else>
        <img src="@/assets/login/error_pay.png" alt="" />
        <p>支付失败</p>
        <p class="tip">正在为您跳转...</p>
      </div>
    </el-dialog>

    <!-- <div  class="marking"> -->
    <div class="mark" v-show="wx_code_mark">
      <p>
        支付剩余时间 <b style="color: #479dff">{{ wx_code_time }}</b> 秒
      </p>
      <div class="code_b" ref="qrCodeUrl" id="qrCodeUrl">
        <!-- <img src="@/assets/login/success_pay.png" alt="" /> -->
      </div>
      <div class="close" @click="handleCloseWXcode">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
import QRCode from "qrcodejs2";
import api from "@/config/api";
var timer = null;
var timer_request = null;
import mock_group from "@/components/mock_group.vue";
export default {
  name: "pay",
  components: { mock_group },
  data() {
    return {
      pay_List: [
        {
          id: 1,
          src: require("@/assets/login/al_icon.png"),
          name: "支付宝支付",
        },
        {
          id: 2,
          src: require("@/assets/login/wx_icon.png"),
          name: "微信支付",
        },
        // {
        //   id: 3,
        //   name: "学习币支付",
        // },
      ],
      current_index: 0,
      agree_value: true,
      agree_url: "https://www.yanxian.org/",
      dialogVisible: false,
      dialogVisible_pay_status: true,
      order_info: {}, // {oid: '654', price: 599}
      pay_url: "",

      wx_code_mark: false,
      speed: 120,
      wx_code_time: 0, // 秒
    };
  },
  mounted() {
    var base64_checked_str = JSON.parse(Base64.decode(this.$route.params.p));
    this.order_info = base64_checked_str;
  },
  methods: {
    chang_pay(e) {
      this.current_index = e;
    },
    async handlePay() {
      if (this.wx_code_mark) {
        return;
      }
      var param = {
        id: this.order_info.oid,
      };
      if (this.current_index == 0) {
        param.method = 2;
      } else if (this.current_index == 1) {
        param.method = 1;
      }
      try {
        let res = await api.order_pay(param);

        if (this.current_index == 0) {
          var div = document.createElement("div");
          div.innerHTML = res;
          div.style.position = "absolute";
          div.style.left = "-999px";
          div.style.top = "-999px";
          this.$refs.pay_page.appendChild(div);
          document.forms.alipaysubmit.submit();
        } else {
          this.wx_code_time = this.speed;
          this.pay_url = res.result;
          this.wx_code_mark = true;
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: res.result,
            width: 150,
            height: 150,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });

          timer = setInterval(() => {
            this.wx_code_time--;
            if (this.wx_code_time <= 0) {
              clearInterval(timer);
              timer = null;
              clearInterval(timer_request);
              timer_request = null;
              document.getElementById("qrCodeUrl").innerHTML = "";
              this.wx_code_mark = false;
            }
          }, 1000);
          timer_request = setInterval(() => {
            this.handleIsPay();
          }, 2000);
        }
      } catch (e) {
        this.pay_show = true;
        this.pay_status = 0;
      }
    },
    async handleIsPay() {
      try {
        let res = await api.is_pay({
          id: this.order_info.oid,
        });
        if (res.code == 0) {
          this.dialogVisible = true;
          this.dialogVisible_pay_status = true;
          this.$router.push({ path: `/pay_success/${this.order_info.oid}` });
        }
      } catch (e) {
        console.log(e);
      }
    },
    go_web() {
      // window.location.href = this.agree_url;
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
      this.dialogVisible = false;
    },
    handleCloseWXcode() {
      clearInterval(timer);
      timer = null;
      clearInterval(timer_request);
      timer_request = null;
      document.getElementById("qrCodeUrl").innerHTML = "";
      this.wx_code_mark = false;
    },
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
    clearInterval(timer_request);
    timer_request = null;
  },
};
</script>
<style scoped lang="less">
#pay_dialog .el-dialog {
  width: 34%;
  height: 350px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 22vh !important;
}
</style>
<style>
#app {
  min-height: calc(100vh - 451px);
}
</style>

<style lang="less" scoped>
.page {
  background-color: #f5f6f8;
  min-height: calc(100vh - 451px);
  padding-bottom: 100px;
}
.pay_bg {
  .page_connernt {
    padding: 30px 40px;
    width: 1200px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px;
    margin: 0 auto;
    text-align: left;
    .top {
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      .left {
        .title {
          font-size: 20px;

          font-weight: 600;
          color: #333333;
          margin-bottom: 5px;
        }
        font-size: 14px;

        font-weight: 500;
        color: #666666;
      }
      .right {
        font-size: 16px;

        font-weight: 500;
        color: #999999;
        line-height: 38px;
        display: flex;
        align-items: center;
        p {
          font-size: 22px;

          font-weight: 800;
          color: #f96158;
          span {
            font-size: 28.36px;
          }
        }
      }
    }
  }
  .tab_wrap {
    padding-top: 33px;
    display: flex;
    align-items: center;
    .none {
      display: none;
    }
    li.active {
      border: 1px solid #479dff;
    }
    li.active::after {
      content: "";
      position: absolute;
      width: 38px;
      height: 38px;
      // background: #479dff;
      border-radius: 8px 0px 8px 0px;
      bottom: -1px;
      right: -1px;
      background: url(../assets/login/is_defult.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 38px;
      height: 38px;
    }
    li {
      cursor: pointer;
      position: relative;
      width: 220px;
      height: 70px;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 16px;

      font-weight: 500;
      color: #333333;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin-top: 64px;
    justify-content: space-between;
    .left {
      color: #666666;
      font-size: 14px;

      font-weight: 500;
      line-height: 38px;
      display: flex;
      align-items: center;
      .choose {
        margin-right: 10px;
      }
      p {
        color: #333333;
        cursor: pointer;
      }
    }
    .btn {
      width: 160px;
      height: 40px;
      background: #479dff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.zhanwei {
  height: 22px;
}
#pay_dialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 92px;
    height: 92px;
  }
  p:nth-of-type(1) {
    font-size: 22px;

    font-weight: 500;
    color: #333333;
    margin-top: 29px;
  }
  p:nth-of-type(2) {
    font-size: 16px;

    font-weight: 500;
    color: #666666;
    margin-top: 19px;
  }
}
.code_b {
  width: 150px;
  height: 150px;
  background: #eee;
  margin: 0 auto;
  margin-top: 50px;
}
.marking {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mark {
  position: fixed;
  width: 300px;
  height: 300px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  box-shadow: 0px 5px 33px 2px rgba(182, 208, 249, 0.52);
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
  p {
    color: #606266;
    font-size: 14px;
    padding-top: 10px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    color: #999999;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
  }
}
</style>
